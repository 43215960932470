
import { IDcotorListItem } from "@/models/doctor";
import { defineComponent, PropType, reactive } from "vue";

export default defineComponent({
  props: {
    doctor: {
      type: Object as PropType<IDcotorListItem>,
      required: true,
    },
    isDetail: {
      type: Boolean,
      default: true,
    },
  },
  name: "doctor item",
  setup(props) {
    const state = reactive({
      image: props.doctor.Image,
      doctorName: props.doctor.DoctorName,
      profiles: props.doctor.Profiles,
      isDetail: props.isDetail,
    });
    return { state };
  },
});
