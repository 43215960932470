
import OrderList from "@/components/primevueCustom/OrderList.vue";
import { IAlert, IResponseData, StatusTexts } from "@/models/common";
import {
  IDcotorListItem,
  IDepts,
  IDoctor,
  ISaveMyDoctorInfo,
} from "@/models/doctor";
import $API from "@/services";
import { AxiosResponse } from "node_modules/axios";
import { useI18n } from "vue-i18n";
import {
  defineComponent,
  onMounted,
  PropType,
  reactive,
  Ref,
  ref,
  watch,
} from "vue";
import { messageType } from "@/models/message";
import Dropdown from "primevue/dropdown";
import OverlayPanel from "primevue/overlaypanel";
import Paginator from "primevue/paginator";
import DoctorItem from "@/components/common/Doctors/DoctorItem.vue";
import { useStore } from "vuex";
import { CommonMutationTypes } from "@/store/modules/common/mutations";

export default defineComponent({
  name: "Doctor List",
  emits: ["selectDoctor"],
  props: {
    doctors: {
      type: Object as PropType<IDoctor>,
      required: true,
    },
    required: Boolean, //필수입력값 표시여부
    isEditable: Boolean,
    notSelectable: Boolean,
    isHeight: {
      type: Boolean,
      default: true,
    },
    isFavorite: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n({ useScope: "global" });
    const store = useStore();
    const doctor: IDoctor = reactive({
      doctorList: props.doctors.doctorList,
      doctorListSplit: props.doctors.doctorListSplit,
      selectedDoctor: props.doctors.selectedDoctor,
      selectedPage: props.doctors.selectedPage,
      displayDoctorRow: props.doctors.displayDoctorRow,
    });

    const selectMyDoctorName = ref("");

    const isNotSelectable = ref(props.notSelectable);
    const selectDeptKey = ref();
    const isRequired = ref(!!props.required);
    const isHeightLimit = ref(props.isHeight);

    const toggleTargetDoctor: Ref<IDcotorListItem> = ref({} as IDcotorListItem);

    const countMyDoctor = ref(0);

    const alert: Ref<IAlert> = ref({
      type: messageType.ERROR,
      content: "",
    });

    // dropdown
    const depts = reactive({
      select: null as null | IDepts,
      list: [] as Array<IDepts>,
      displayList: [] as Array<IDepts>,
    });

    const getDeptsList = async () => {
      const {
        data: { ResultData },
      }: AxiosResponse<IResponseData<IDepts>> =
        await $API.DoctorService.getDeptsList();
      if (!Array.isArray(ResultData)) return;
      depts.list = ResultData;

      // 가져온 진료과 중 의사가 있는 진료과만 표시
      const groupbyList = await Promise.all(
        doctor.doctorList
          .map((x) => x.DeptKey)
          .reduce((u: Array<Number>, i: Number) => {
            return u.includes(i) ? u : [...u, i];
          }, [])
      );
      depts.displayList = await Promise.all(
        depts.list.filter((x) => groupbyList.includes(x.DeptKey))
      );
    };

    const descMore = ref("");

    watch(
      () => depts.select,
      async (n) => {
        selectDeptKey.value = n;
        displayList.value = [];

        if (!selectDeptKey.value) {
          displayList.value =
            doctor.doctorListSplit[doctor.selectedPage.valueOf()];
        } else {
          const filterList = await Promise.all(
            doctor.doctorList.filter((item) => {
              return item.DeptKey === selectDeptKey.value;
            })
          );
          //await Promise.all(
          displayList.value = filterList;
        }

        if (props.isFavorite) {
          console.log("1", props.isFavorite);
          displayList.value = displayList.value.filter((x) => x.MyDoctor);
        }
      }
    );

    //보여줄 의사 리스트
    const displayList: Ref<Array<IDcotorListItem>> = ref([]);
    const op$: Ref<OverlayPanel | null> = ref(null);

    /**
     * 의사 프로필 상세보기 click event
     */
    const opToggle = (event: any, profile: string) => {
      event?.preventDefault();
      descMore.value = profile;
      op$.value?.toggle(event);
    };

    const countedMydoctor = async () => {
      countMyDoctor.value = 0;

      const count = await Promise.all(
        displayList.value.filter(async (x) => x.MyDoctor)
      );
      countMyDoctor.value = count.length;
    };

    onMounted(async () => {
      store.commit(CommonMutationTypes.SET_IS_LODING, true);
      displayList.value = doctor.doctorListSplit[doctor.selectedPage.valueOf()];

      if (props.isFavorite)
        displayList.value = displayList.value.filter((x) => x.MyDoctor);

      await getDeptsList();
      await countedMydoctor();
      store.commit(CommonMutationTypes.SET_IS_LODING, false);
    });
    return {
      doctor,
      props,
      t,
      isRequired,
      alert,
      displayList,
      depts,
      emit,
      op$,
      opToggle,
      descMore,
      isNotSelectable,
      toggleTargetDoctor,
      isHeightLimit,
      countMyDoctor,
      countedMydoctor,
      store,
      selectMyDoctorName,
    };
  },
  methods: {
    /**
     * orderlist update:selection event 받아서 selectDoctor emit
     */
    updateSelection(val: Array<IDcotorListItem>) {
      if (this.isNotSelectable) return;
      this.doctor.selectedDoctor = val;
      this.emit("selectDoctor", this.doctor);
    },

    /**
     * 의사 즐겨찾기(마이닥터) click event
     */
    async toggleFavorites(e: Event, doctor: IDcotorListItem) {
      e.stopPropagation();
      const params: ISaveMyDoctorInfo = {
        DoctorKey: doctor.DoctorKey.valueOf(),
        MyDoctor: 1 >> doctor.MyDoctor,
      };

      const res: AxiosResponse = await $API.DoctorService.SaveMyDoctorInfo(
        params
      );
      if (res.statusText === StatusTexts.OK || res.status === 200) {
        doctor.MyDoctor = 1 >> doctor.MyDoctor;
      }
      const selectedDoctor = await Promise.all(
        this.doctor.doctorList.filter((x) => x.DoctorKey === doctor.DoctorKey)
      );
      this.toggleTargetDoctor = selectedDoctor[0];

      if (doctor.MyDoctor === 1) {
        this.selectMyDoctorName = doctor.DoctorName.valueOf();
        this.alert = {
          content: "한 번 더 클릭하여 관심의사를 해제할 수 있습니다.",
          type: messageType.INFO,
        };
      }
      await this.countedMydoctor();
    },
  },
  components: {
    OrderList,
    Dropdown,
    OverlayPanel,
    Paginator,
    DoctorItem,
  },
});
