import moment from "moment";

export interface IDateTime {
  date:string,
  time:string
}

export const addDate=(d:number)=>{
    return new Date(new Date().setDate(new Date().getDate() + d))
};

  /**   
   * @returns today 'yyyy-MM-dd'
   */
   export const getStringNow = ()=>{
    return new Date().toJSON().slice(0, 10).replace(/-/g, "-");
  };
  /**
   * 
   * @param d increase day
   * @returns today + d 'yyyy-MM-dd'
   */
   export const getStringSetDay = (d:number)=>{
    return new Date(new Date().setDate(new Date().getDate() + d))
          .toJSON()
          .slice(0, 10)
          .replace(/-/g, "-");
  };
    /**
   * 
   * @param m increase month
   * @returns today + m 'yyyy-MM-dd'
   */
     export const getStringSetMonth = (m:number)=>{
    return new Date(new Date().setMonth(new Date().getMonth() + m))
    .toJSON()
    .slice(0, 10)
    .replace(/-/g, "-")
  };
  /**
   * 
   * @param dateTime 'yyyyMMddHHmmss'
   * @returns '{date:'yyyy-MM-dd', time:'HH:mm'}'
   */
   export const splitDatetime = (dateTime:string): IDateTime=>{
    const newDateTime = {
    date: dateTime.length>7 ? `${dateTime.substr(0, 4)}-${dateTime.substr(4, 2)}-${dateTime.substr(6, 2)}`:"",
    time: dateTime.length===14 ? `${dateTime.substr(8, 2)}:${dateTime.substr(10, 2)}`:"",
    }
    return newDateTime;
  };
/**
   * 
   * @param dateTime 'yyyyMMddHHmmss'
   * @returns 'yyyy-MM-dd HH:mm'
   */
  export const splitDatetimeStringToString = (dateTime:string)=>{
    if(typeof dateTime === "string"){
      const datetime = splitDatetime(dateTime);
      return `${datetime.date.toString()} ${datetime.time.toString()}`
    }
  };


  export const convertSecondsTo=(seconds:string)=>{
    const intSecondes = parseInt(seconds);

    const m  = Math.floor(intSecondes/60);
    const s = intSecondes%60;

    return {
       m, s
    }
  };
  export const StringDateTime = (date:string, time:string)=>{
    return moment(new Date(date)).format("YYYYMMDD")+time.replace(/:/, "")+'00';
  }
  export const StringDateWith = (date:string, withStr:string="") =>{
    return moment(new Date(date)).format(`YYYY${withStr}MM${withStr}DD`);
  }
  /**
   * 
   * @param date1 D-day
   * @param date2 기준일, 없으면 오늘
   */
  export const durationDate = (date1:string, date2?:string)=>{
    const d1 = moment(splitDatetime(date1).date);    
    const d2 = moment(date2? splitDatetime(date2).date:getStringNow());
    

    return moment.duration(d1.diff(d2)).asDays();
  }
const DateFormat = {
  addDate,
  getStringNow,
  getStringSetDay,
  getStringSetMonth,
  splitDatetime,
  splitDatetimeStringToString,
  convertSecondsTo,
  StringDateTime,
  StringDateWith,
  durationDate
}
export default DateFormat;