
import { IDoctorScheduled, ITimeList } from "@/models/common";
import { IOnlineCarePurpose } from "@/models/onlineCare";
import DateFormat from "@/utils/date-format";
import moment from "moment";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
  watch,
} from "vue";

export default defineComponent({
  name: "Doctor Scheduled",

  props: {
    purpose: {
      type: Object as PropType<IOnlineCarePurpose>,
      required: true,
    },
    timeList: {
      type: Object as PropType<ITimeList>,
      required: true,
    },
    isEditable: {
      type: Boolean,
      required: true,
    },
    doctorScheduled: {
      type: Object as PropType<IDoctorScheduled>,
      required: true,
    },
    required: Boolean,
    isLine: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const state = reactive({
      purpose: props.purpose,
      timeList: props.timeList as ITimeList,
      isEditable: props.isEditable,
      doctorScheduled: props.doctorScheduled,
      disabledDays: [] as Array<number>,
      enableTimeList: [...props.timeList.am, ...props.timeList.pm],
      invalidDates: [] as Array<Date>,
      disabled: true, // 수정시 일자 및 시간은 수정 불가, 의사 선택시 수정 가능
      isLine: props.isLine,
    });

    const date = reactive({
      minDate: new Date(),
      maxDate: DateFormat.addDate(+14),
    });

    const isRequired = ref(!!props.required);

    /**
     * 일자 변경 시 해당 일자에 가능한 시간 가져오기
     */
    watch(
      () => state.purpose.date,
      async (n) => {
        if (n) {
          await setEnalbledTimes(n);
        }
      }
    );

    /**
     * 의뢰 가능한 시간
     */
    const setEnalbledTimes = async (n: String | null) => {
      if (n) {
        const date = new Date(n.toString()).getDay();
        const doctorScheduledDays =
          state.doctorScheduled.DoctorScheduledDays?.toString().split(
            ","
          ) as Array<String> | null;
        let enabledTime = 0;
        if (!doctorScheduledDays) return;
        doctorScheduledDays.forEach((x) => {
          if (x.startsWith(date.toString())) {
            enabledTime = Number.parseFloat(x.split("=")[1]);
          }
        });
        if (enabledTime === 1) {
          state.enableTimeList = [...state.timeList.am];
        } else if (enabledTime === 2)
          state.enableTimeList = [...state.timeList.pm];
        else if (enabledTime === 3)
          state.enableTimeList = [...props.timeList.am, ...props.timeList.pm];

        //선택한 날짜에 예약된 시간 제외하고 표시
        const selectDate = moment(
          new Date(state.purpose.date.toString())
        ).format("YYYY-MM-DD");

        const selectDateScheduledTime =
          state.doctorScheduled.ReqScheduleds.filter((x) => {
            if (
              DateFormat.splitDatetime(x.ScheduledDatatime.toString()).date ===
              selectDate
            ) {
              return x;
            }
          }).map((x) => {
            return DateFormat.splitDatetime(x.ScheduledDatatime.toString())
              .time;
          });

        state.enableTimeList = state.enableTimeList.filter(
          (time) => !selectDateScheduledTime.includes(time)
        );

        //선택한 날짜가 오늘이면 두시간 이후만 예약 가능
        const today = moment(new Date()).format("YYYY-MM-DD");
        if (selectDate === today) {
          const currentTime = moment(new Date(), "HH:mm");

          state.enableTimeList = state.enableTimeList.filter(
            (time) =>
              moment
                .duration(currentTime.diff(moment(time, "HH:mm")))
                .asMinutes() < -60
          );
        }
      }
    };

    /**
     * 의사 별 예약 불가 일자
     */
    const setDisabledDays = () => {
      const doctorScheduledDays =
        state.doctorScheduled.DoctorScheduledDays.toString().split(",");

      if (!doctorScheduledDays) return;

      const disabledDays1 = [0, 1, 2, 3, 4, 5, 6];
      if (doctorScheduledDays?.length > 0) {
        let enabledDays = doctorScheduledDays.map((x) => {
          return x.split("=")[0];
        });

        state.disabledDays = disabledDays1.filter((x) => {
          return !enabledDays.includes(x.toString());
        });
      }
    };

    const selectDate = computed(() =>
      !!state.purpose.date == true
        ? moment(new Date(state.purpose.date.toString())).format("YYYY-MM-DD")
        : ""
    );

    onMounted(() => {
      setDisabledDays();
      state.disabled = false;
    });

    return { state, date, isRequired, selectDate };
  },
  methods: {},
  components: {
    Calendar,
    Dropdown,
  },
});
