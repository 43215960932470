
import { IResponseData } from "@/models/common";
import { IPharmacyItem } from "@/models/pharmacy";
import $API from "@/services";
import { AxiosResponse } from "node_modules/axios";
import { defineComponent, onMounted, PropType, reactive, Ref, ref } from "vue";
import Button from "primevue/button";
import AutoComplete from "primevue/autocomplete";
import { useI18n } from "vue-i18n";
import { RequestStatuses } from "@/models/request";

export default defineComponent({
  name: "Pharmacy List",
  props: {
    isDefault: {
      type: Boolean,
      required: false,
    },
    selectedKey: {
      type: Number,
      required: false,
    },
    isEditable: {
      type: Boolean,
      required: false,
    },
    requestStatus: {
      type: Number as PropType<RequestStatuses | null>,
      required: false,
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      //기본 약국 선택 버튼
      isDefault: !!props.isDefault,
      isEditable: !!props.isEditable,
      requestStatus: props.requestStatus,
    });
    const pharmacyKey: Ref<number | null> = ref(props.selectedKey ?? null);
    const pharmacies = reactive({
      // 전체 약국
      pharmacyList: [] as Array<IPharmacyItem>,
      // 검색한 약국
      filteredPharmacy: [] as Array<IPharmacyItem>,
    });

    const { t } = useI18n({ useScope: "global" });

    const selectedPharmacy: Ref<IPharmacyItem | null> = ref(null);

    /**
     * AutoComplete complete event
     */
    const searchPharmacy = (event: { originalEvent: Event; query: string }) => {
      setTimeout(async () => {
        if (!event.query.trim().length) {
          pharmacies.filteredPharmacy = [...pharmacies.pharmacyList];
        } else {
          pharmacies.filteredPharmacy = await Promise.all(
            pharmacies.pharmacyList.filter((pharmacy) => {
              const search =
                `${pharmacy.Address1} ${pharmacy.Address2} ${pharmacy.Address3} ${pharmacy.Address4} ${pharmacy.PharmacyName}`
                  .toLowerCase()
                  .search(event.query.toLowerCase());
              if (search > 0) {
                return pharmacy;
              }
            })
          );
        }
      }, 250);
    };

    /**
     * 약국 선택시 약국key, 미 선택시 null emit
     */
    const updateKey = (key: Number | null) => {
      emit("item-select", key);
    };

    const selectItem = (event: {
      originalEvent: Event;
      value: IPharmacyItem;
    }) => {
      updateKey(event.value.PharmacyKey);
    };

    const selectedNull = () => {
      pharmacyKey.value = null;
      selectedPharmacy.value = null;
      updateKey(null);
    };

    const getPharmacyList = async () => {
      const res: AxiosResponse<IResponseData<IPharmacyItem>> =
        await $API.PharmacyService.getPharmacyList();
      const {
        data: { ResultData },
      } = res;
      if (Array.isArray(ResultData)) pharmacies.pharmacyList = ResultData;
    };
    onMounted(async () => {
      await setPharmacy();
    });

    const getPharmacyItem = async () => {
      const selectitem = await Promise.all(
        pharmacies.pharmacyList.filter((pharmacy) => {
          if (pharmacy.PharmacyKey === pharmacyKey.value) return pharmacy;
        })
      );
      if (selectitem.length > 0) return selectitem[0];
      return null;
    };

    const setPharmacy = async () => {
      await getPharmacyList();
      pharmacyKey.value = props.selectedKey ?? null;

      // pharmacyKey가 -1이면 user의 기본 값 가져오기, 기본값 사용 X
      // if (pharmacyKey.value === -1) await getMyDefulatPharmacyKey();

      selectedPharmacy.value = await getPharmacyItem();
    };

    const getMyDefulatPharmacyKey = async () => {
      const res: AxiosResponse<IResponseData<Number>> =
        await $API.AccountsService.getMyDefaultPharmacyKey();

      const {
        data: { ResultData },
      } = res;
      if (!ResultData) return;

      if (!Array.isArray(ResultData)) {
        pharmacyKey.value = ResultData.valueOf();
        selectedPharmacy.value = await getPharmacyItem();
        updateKey(pharmacyKey.value);
      }
    };

    return {
      pharmacyKey,
      pharmacies,
      searchPharmacy,
      selectItem,
      selectedNull,
      state,
      selectedPharmacy,
      getMyDefulatPharmacyKey,
      t,
      RequestStatuses,
    };
  },
  components: {
    AutoComplete,
    Button,
  },
});
