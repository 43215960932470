
import { defineComponent, reactive, ref } from "vue";
import FileUpload from "@/components/primevueCustom/FileUpload.vue";
import { useStore } from "@/store";
import { AuthActionTypes } from "@/store/modules/auth/actions";
import { AuthMutationTypes } from "@/store/modules/auth/mutations";
import { CommonMutationTypes } from "@/store/modules/common/mutations";
import { isMobile } from "mobile-device-detect";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "File Uploader",
  emits: ["progress"],
  props: {
    // 수정가능
    isEditable: {
      type: Boolean,
      required: true,
    },
    //파일 업로드 주소
    url: {
      type: String,
      required: true,
    },
    //압축파일 업로드
    isAllowZip: {
      type: Boolean,
      required: false,
    },
    //동영상 업로드
    isAllowVideo: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const { t } = useI18n();

    const accept = ref(
      "image/gif, image/jpg, image/png, image/jpeg, application/pdf, .pdf"
    );

    if (props.isAllowZip) {
      accept.value += ",application/zip, application/x-zip-compressed";
    }

    if (props.isAllowVideo) {
      accept.value += ",video/mp4, video/avi, video/quicktime";
    }

    const state = reactive({
      isEditable: props.isEditable,
      url: props.url,
      progressValue: 50 as Number,
      accept: accept,
    });

    const store = useStore();
    if (store.getters.getIsFileUpdate)
      store.commit(CommonMutationTypes.SET_IS_LODING, true);

    return { state, store, isMobile, t };
  },
  methods: {
    /**
     * onlinecare request, secondopinion request에서 호출
     * @param url upload path
     */
    async upload(url?: string) {
      this.store.commit(AuthMutationTypes.SET_TOKEN, "");
      await this.store.dispatch(AuthActionTypes.GET_TOKEN, null);
      this.store.commit(CommonMutationTypes.FILE_UPDATE, true);

      if (url) this.state.url = url;

      if (this.$refs.fileUpload$ as any)
        await (this.$refs.fileUpload$ as any).upload(url);
    },

    /**
     * onlinecare request, secondopinion request에서 호출
     */
    getFilesCount() {
      const count = (this.$refs.fileUpload$ as any).getFilesCount() as number;
      return count;
    },

    /**
     * upload progress
     */
    filesProgress(progress: {
      originalEvent: { loaded: number; total: number };
      progress: number;
    }) {
      if (
        progress.originalEvent.loaded === progress.originalEvent.total &&
        progress.progress === 100
      ) {
        this.store.commit(CommonMutationTypes.FILE_UPDATE, false);
        this.$emit("progress");
      }
    },
  },

  components: {
    FileUpload,
  },
});
