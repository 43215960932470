
import router from "@/router";
import { defineComponent, onUpdated, reactive, ref } from "vue";
import { onBeforeRouteLeave } from "vue-router";

export default defineComponent({
  name: "Page Frame",
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Number,
      required: false,
    },
    isClose: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const currentPage = ref(props.modelValue ?? 0);

    const close = () => {
      router.back();
    };

    const state = reactive({
      isClose: props.isClose,
    });

    onUpdated(() => {
      if (currentPage.value === props.modelValue) return;
      else currentPage.value = props.modelValue ?? 0;
    });

    onBeforeRouteLeave((to, from, next) => {
      if (currentPage.value === 0) next();
      else {
        emit("update:modelValue", currentPage.value - 1);
        next(false);
      }
    });

    return { close, state };
  },
  components: {},
});
