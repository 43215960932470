
import { IDcotorListItem, IDoctor, PossibleTypes } from "@/models/doctor";
import { defineComponent, onMounted, PropType, reactive } from "vue";
import DoctorList from "@/components/common/Doctors/DoctorList.vue";
import _ from "lodash";
import { getDoctorList } from "@/services/doctor";

export default defineComponent({
  name: "doctors",
  emits: ["selectDoctor", "update:pDoctors"],
  props: {
    required: Boolean, //필수입력값 표시여부
    isEditable: Boolean,
    notSelectable: Boolean, // 의사 선택(request), 선택불가(마이페이지)
    possibleTypes: {
      type: Number as PropType<PossibleTypes>,
      required: true,
    },
    doctorKey: Number,
    pDoctors: {
      type: Object as PropType<IDoctor>,
    },
    isHeight: {
      type: Boolean,
      default: true,
    },
    isFavorite: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const doctors: IDoctor | null = reactive({
      doctorList: [] as Array<IDcotorListItem>,
      doctorListSplit: [] as Array<Array<IDcotorListItem>>,
      selectedDoctor: [] as Array<IDcotorListItem>,
      selectedPage: 0,
      displayDoctorRow: 0,
    });

    const state = reactive({
      doctorListLoading: false,
      required: props.required,
      isEditable: props.isEditable,
      possibleTypes: props.possibleTypes,
      doctorKey: props.doctorKey,
      notSelectable: props.notSelectable,
      isHeight: props.isHeight,
      isFavorite: props.isFavorite,
    });

    const selectDoctor = (doctor: IDoctor) => {
      if (state.notSelectable) return;

      doctors.selectedDoctor = doctor.selectedDoctor;
      doctors.selectedPage = doctor.selectedPage;

      emit("selectDoctor", doctors);
    };

    const setDoctorList = async () => {
      if (props.pDoctors) {
        doctors.doctorList = props.pDoctors.doctorList;
      } else {
        doctors.doctorList = (
          (await getDoctorList(state.possibleTypes)) as IDoctor
        ).doctorList;
      }
    };

    const onSetData = async () => {
      if (props.pDoctors) {
        doctors.doctorList = props.pDoctors.doctorList;
        doctors.doctorListSplit = props.pDoctors.doctorListSplit;
        doctors.selectedDoctor = props.pDoctors.selectedDoctor;
        doctors.selectedPage = props.pDoctors.selectedPage;
        doctors.displayDoctorRow = props.pDoctors.displayDoctorRow;
      } else {
        await setDoctorList();

        if (state.doctorKey) {
          await Promise.all(
            (doctors.selectedDoctor = doctors.doctorList.filter((x, index) => {
              if (x.DoctorKey === state.doctorKey) {
                doctors.selectedPage = Number.parseInt(
                  (index / doctors.displayDoctorRow.valueOf()).toString()
                );
                return x;
              }
            }))
          );
        }

        if (!doctors.displayDoctorRow) {
          doctors.doctorListSplit = [doctors.doctorList];
          doctors.selectedPage = 0;
        } else {
          if (state.isEditable)
            doctors.doctorListSplit = _.chunk(
              doctors.doctorList,
              doctors.displayDoctorRow.valueOf()
            );
          else {
            doctors.doctorList = doctors.selectedDoctor;
            doctors.doctorListSplit = [doctors.selectedDoctor];
            doctors.selectedPage = 0;
          }
        }

        // edit에서 의사 선택 화면 갈 때 마다 새로 load 방지
        emit("update:pDoctors", doctors);
      }

      state.doctorListLoading = true;
    };

    onMounted(async () => {
      await onSetData();
    });

    return { doctors, state, selectDoctor, onSetData };
  },
  components: {
    DoctorList,
  },
});
