import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DoctorList = _resolveComponent("DoctorList")

  return (_openBlock(), _createBlock("div", null, [
    (_ctx.state.doctorListLoading)
      ? (_openBlock(), _createBlock(_component_DoctorList, {
          key: 0,
          onSelectDoctor: _ctx.selectDoctor,
          doctors: _ctx.doctors,
          required: _ctx.state.required,
          isEditable: _ctx.state.isEditable,
          notSelectable: _ctx.state.notSelectable,
          isHeight: _ctx.state.isHeight,
          isFavorite: _ctx.state.isFavorite,
          ref: "doctorList$"
        }, _createSlots({ _: 2 }, [
          (_ctx.$slots.title)
            ? {
                name: "title",
                fn: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "title")
                ])
              }
            : undefined
        ]), 1032, ["onSelectDoctor", "doctors", "required", "isEditable", "notSelectable", "isHeight", "isFavorite"]))
      : _createCommentVNode("", true)
  ]))
}