
import { RequestKindTypes, RequestSteps } from "@/models/request";
import { defineComponent, onUpdated, PropType, reactive } from "vue";

export default defineComponent({
  name: "Request Step",
  props: {
    kind: {
      type: String as PropType<RequestKindTypes>,
      required: true,
    },
    step: {
      type: Number as PropType<RequestSteps>,
      required: true,
    },
  },
  setup(props) {
    const state = reactive({
      kind: props.kind,
      step: props.step,
    });

    const steps = reactive({
      tele: ["진료과 및 의사선택", "희망 일/시 선택", "증상 및 파일 첨부"],
      second: ["진료과 및 의사선택", "증상 및 파일 첨부"],
    });

    onUpdated(() => {
      if (props.step === state.step) return;
      else state.step = props.step;
    });
    return { state, RequestKindTypes, steps };
  },
});
